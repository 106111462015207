import React, { useState } from 'react'
import 'styles/index.less'
import Icon from 'components/Icon'

function Index() {
  return (
    <div className="full-screen">
      <div className="container full-height d-flex flex-column justify-content-center align-items-center">
        <h1 className="font-logo text-center blue-gradient">ayabala</h1>
        <div className="text-center">
          <a className="fs_22 h_purple mr_3p5" href="http://blog.ayabala.com" target="_blank">
            <Icon iconName="blog"/><span>Blog</span>
          </a>
          <a className="fs_22 h_purple" href="https://github.com/FantasyNJ" target="_blank">
            <Icon className="mr_p3" iconName="github"/><span>GitHub</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Index
