import React from 'react'
import Layout from '../components/Layout'
import Index from '../components/index/Index'

export default () => (
  <>
    <Layout>
      <Index />
    </Layout>
  </>
)
